<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<FormKTCInstallmentPlan
		v-else
		:is-submitting="installment.create.isCreating"
		:group-id="$route.params.id"
		:method-id="$route.params.methodId"
		:gateway="paymentMethodDetails.gateway"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FormKTCInstallmentPlan from '@/components/FormKTCInstallmentPlan.vue';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'KTCInstallmentPlanCreate',

	components: {
		FormKTCInstallmentPlan,
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		...mapState('payments', ['installment']),

		...mapGetters({
			paymentMethodDetails: 'payments/paymentMethodDetails',
		}),
	},
	async created() {
		this.isLoading = true;
		await this.getPaymentMethod(this.$route.params.methodId);
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			createPlan: 'payments/createInstallmentPlan',
			getPaymentMethod: 'payments/getPaymentMethod',
		}),

		async handleSubmit(params) {
			const methodId = this.$route.params.methodId;
			try {
				await this.createPlan({
					methodId,
					params,
				});

				this.$router.push({ name: ROUTE_NAME.KTC_INSTALLMENT_PLAN_LIST });
			} catch {
				// error
			}
		},
	},
};
</script>
